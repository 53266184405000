<template>
  <!-- 智慧园区 -->
  <div class="content-box">
     
    <div class="title-box">
      <div class="title-name">污水处理</div>
      <div class="title-p">
        水体污染和富营养化危及水生态系统、水环境状况的恶化已成为建设生态<br/>
        文明及绿色发展的短板，我国水生态环境状况不容乐观
      </div>
    </div>
    <div class="function">
      <div class="scenes-box">
        <!-- <h2 class="one">PLAN ADVANTAGE</h2> -->
        <h2>方案优势</h2>
      
        <div class="scenes-bg">
           <div>
   <div class="leftTop">
              <img class="icons" src="../../assets/image/sewage/ssx.png" alt="" />
             实时性
            </div>
            <div class="targetContent">
            系统无时延，无需轮巡就可以同步接收、处理多个/所有数据采集点的数据。可很好的满足系统对数据采集和传输实时性的要求。
            </div>
           </div>
         
          
          <div  >
            <div class="leftTop">
              <img class="icons" src="../../assets/image/sewage/yckz.png" alt="" />
              远程控制
            </div>
            <div class="targetContent">
             通过网络双向系统还可实现对视频设备进行远程控制，进行参数调整、转动云台等控制操作。
            </div>
          </div>
          <div>
            <div class="leftTop">
              <img class="iconss" src="../../assets/image/sewage/cbd.png" alt="" />
             成本低
            </div>
            <div class="targetContent">
               由于采用现有网络或4G/5G网络，只需安装好设备就可以，不需要进行专门布线，前期投资少、见效快，后期升级、维护成本低；
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="function">
      <!-- <h2 class="one">PLATFORM ARCHITECTURE</h2> -->
      <h2>平台架构</h2>
      <div class="services">
        <div class="left"></div>
      </div>
    </div>
    <div class="function">
      <!-- <h2 class="one">FUNCTION OF THE SCENE</h2> -->
      <h2>功能场景</h2>
    </div>
    <div class="scene">
      <div class="img1">
        <div class="sceneBox">
          <div class="sceneIcon icon1"></div>
          <div class="sceneTitle">资产保养</div>
          <div class="sceneContents">
            通过完善的设备和备件资产台账管理，本系统提供了多样化的报表，从而实现了对生产设备的科学化、规范化和信息化管理，不仅有效延长了设备的使用寿命，还大幅提升了设备的使用效率。
          </div>
        </div>
      </div>

      <div class="img2">
        <div class="sceneBox">
          <div class="sceneIcon icon2"></div>
          <div class="sceneTitle">工艺监控</div>
          <div class="sceneContents">
            本系统通过图形化界面实时监视生产工艺流程中各种设备的运行状况，并在生产过程中出现异常时及时发出告警，从而极大地增强了生产运营的稳定性。
          </div>
        </div>
      </div>
      <div class="img3">
        <div class="sceneBox">
          <div class="sceneIcon icon3"></div>
          <div class="sceneTitle">辅助决策</div>
          <div class="sceneContents">
             本系统提供多种报表统计功能。对各阶段、各时期、各类生产运行数据可进行统计，报表分析，并以直观的图表形式形式，如历史生产数据报表，对辅助管理者的决策提供强大的支持。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 
export default {
  name: "",
  props: {},
  components: {
    
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
     
  },
  created() {},
  mounted() {
 
  },
};
</script>

<style scoped lang="scss">
h2 {
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 5px;
}
.one {
  color: #b0b7bf;
}
.content-box {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  .title-box {
    width: 100%;
    color: #fff;
    height: 625px;
    padding: 0 19%;
    background: url("../../assets/image/sewage/wushuibg.png") no-repeat;
    background-size: 100% 100%;
    margin-top: 100px;
    .title-name {
      font-size: 44px;
      font-weight: normal;
      color: #ffffff;
      transform: translateY(190px);
    }
    .title-p {
      width: 55%;
      font-size: 20px;
    
      line-height: 28px;
      transform: translateY(220px);
      color: #ffffff;
    }
  }
 
  .function {
    padding: 4% 19% 0;
    background-color: #fff;

    .scenes-box {
      background-color: #fff;

      .scenes-bg {
        margin-top: 35px;
        width: 100%;

        height: 208px;
        display: flex;
        > div {
          position: relative;
          width: 30%;
          height: 100%;
          background: url("../../assets/image/wisdomWater/mbbj.png")
            no-repeat;
          background-size: 100% 100%;
          margin: 0 10px;
          .leftTop {
            width: 40%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #fff;
            border-bottom-right-radius: 25px;

            background-color: #c7b090;
          }
          .targetContent {
            margin: 5% 6% 0;
            color: #6b6b6b;
            line-height: 30px;
          }
        }
        .icons {
          width: 24px;
          height: 24px;
          vertical-align: middle;
        }
        .iconss {
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin-bottom: 3px;
        }
      }
      .scenes-text {
        width: 100%;
        height: 400px;
        text-align: center;
        background-color: #fff;
        .title {
          font-size: 28px;
          font-weight: 600;
          padding: 20px 0;
          color: #333333;
        }
        .text-box {
          display: flex;
          .text-box-item {
            width: 16%;
            font-size: 16px;
            color: #6b6b6b;
            margin-top: 60px;
            border-right: 1px solid #dfdfdf;
            p {
              padding: 10px 0;
            }
          }
        }
      }
    }
    .services {
      width: 100%;
     
      display: flex;
      background-color: #fff;
      .left {
        width: 100%;
        height: 766px;
        margin-top: 4%;
        background: url("../../assets/image/sewage/jiagou.png") no-repeat;
        background-size: 100% 100%;
      }
     
    }
    .nh-text {
      font-size: 28px;
      margin: 20px 0;
      color: #6b6b6b;
    }
  }
}

.scene {
  padding: 3% 18.8%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  overflow: hidden;

  
  .sceneBox {
    cursor: pointer;
    .sceneContents {
      padding: 10px 30px;
      color: white;
      font-size: 18px;
      line-height: 30px;
       
    }
     
    width: 100%;
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 2%;
    .sceneIcon {
      margin-top: 80px;
      width: 44px;
      height: 44px;
      z-index: 99;
    }
    .sceneTitle {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin: 5px 0;
      opacity: 1;
      z-index: 99;
      box-sizing: border-box;
      padding-bottom: 10px;
      border-bottom: 1px solid #fff;
    }
    .sceneContent {
      color: #fff;
      margin: 10px 40px;
      z-index: 99;
      display: none;
    }
  }
}
.img1 {
  width: 380px;
  background: url("../../assets/image/sewage/zcbybg.png") no-repeat;
  background-size: 100% 100%;
  // margin: 0 5%;
}
.img2 {
  width: 380px;
  background: url("../../assets/image/sewage/gyjkbg.png") no-repeat;
  background-size: 100% 100%;
  //  margin: 0 5%;
}
.img3 {
  width: 380px;
  background: url("../../assets/image/sewage/fzjcbg.png") no-repeat;
  background-size: 100% 100%;
  //  margin: 0 5%;
}

.icon1 {
  background: url("../../assets/image/sewage/zcbyicon.png") no-repeat;
  background-size: 100% 100%;
}
.icon2 {
  background: url("../../assets/image/sewage/gyjkicon.png") no-repeat;
  background-size: 100% 100%;
}
.icon3 {
  background: url("../../assets/image/sewage/fzjcicon.png") no-repeat;
  background-size: 100% 100%;
}
</style>
